import axios from 'axios';
import Keycloak from 'keycloak-js';
import { wpUrl } from '../wp';
import { logError } from '../utils';

// Wordpress
// -------------------------------------

export const wp = axios.create({
    baseURL: wpUrl(),
});

// Claims
// -------------------------------------
export const unitedClaimsBaseUrl = 'claims.unitedvanlines.com';
export const mayflowerClaimsBaseUrl = 'claims.mayflower.com';

// Requests

// Unigroup API
// -------------------------------------

export const keycloak = Keycloak(`/keycloak-${process.env.KEYCLOAK_ENV}-${process.env.THEME}.json`);
const baseUrl = (() => {
    switch (process.env.API_ENV) {
        case 'local':
            return 'http://localhost:8000/';
        case 'dev':
            return 'https://devcustomerportalapi.cloud1.unigroup.com/';
        case 'stage':
            return 'https://stgcustomerportalapi.cloud1.unigroup.com/';
        case 'prod':
            return 'https://customerportalapi.cloud.unigroup.com/';
        default:
            return 'http://localhost:8000/';
    }
})();

export const surveySchedulerAppUrl = (() => {
    switch (process.env.API_ENV) {
        case 'local':
            // return 'https://devmysurvey.unigroup.com';
        return 'http://localhost:8075';
        case 'dev':
            return 'https://devmysurvey.unigroup.com';
        case 'stage':
            return 'https://stgmysurvey.unigroup.com';
        case 'prod':
            return 'https://mysurvey.unigroup.com';
        default:
            return 'http://localhost:8000/';
    }
})();

export const uni = axios.create({
    baseURL: baseUrl,
});

uni.interceptors.request.use(
    async (config) => {
        try {
            await keycloak.updateToken(5);
            config.headers.Authorization = `Bearer ${keycloak.token}`;
        } catch {
            keycloak.login();
        }
        return config;
    },
    (error) => Promise.reject(error),
);

// Logout
export const logout = () => {
    keycloak.logout({ redirectUri: window.location.origin });
};

// User Profile
export const getMoveInfo = (trackingId) => {
    if (trackingId) return uni.get(`/moveinfo/${trackingId}`);
    else return uni.get('/moveinfo');
};

export const fetchDocument = (formId, formType, callback, errorCallback) => {
    uni.get(`/forms/eacceptance/${formId}?formType=${formType}`, {
        responseType: 'blob',
    })
        .then((response) => {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const open = window.open(fileURL);
            if (open == null || typeof open == 'undefined') {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(file);
                link.download = formType;
                link.click();
            }
            callback();
        })
        .catch((err) => {
            logError(err);
            if (errorCallback) {
                errorCallback(err);
            }
        });
};

// E Acceptance
export const getEAcceptance = (quoteId) => uni.get(`quotes/${quoteId}/eacceptances`);
export const getSpecificForm = (formId, data, formType) => uni.get(`/forms/eacceptance/${formId}?formType=${formType}`, data);
export const getCCKey = (trackingId, brand) => uni.get(`/creditcards/generatekey/${trackingId}?orderBrand=${brand}`);
export const getCCInfo = (trackingId, quoteId, formId) => uni.get(`/creditcards/opportunity/${trackingId}/quote/${quoteId}/forms/${formId}`);
export const postCCInfo = (trackingId, formId, data) => uni.post(`/creditcards/opportunity/${trackingId}/forms/${formId}`, data);
export const putCCInfo = (trackingId, formId, data) => uni.put(`/creditcards/opportunity/${trackingId}/forms/${formId}`, data);
export const deleteCCInfo = (trackingId, formId, data) => uni.delete(`/creditcards/opportunity/${trackingId}/forms/${formId}`, { data });
export const putBooking = (trackingId, eacceptanceId) => uni.put(`/opportunities/${trackingId}/eacceptances/${eacceptanceId}/book`);
export const putPayment = (trackingId, eacceptanceId, isPartial) =>
    uni.put(`/opportunities/${trackingId}/eacceptances/${eacceptanceId}/pay?isPartial=${isPartial}`);

// Timeline
export const getTimeline = (trackingId) => uni.get(`/moveinfo/${trackingId}/timeline`);
export const getTimelineContentResidential = () => wp.get('/acf/v3/options/residential-milestones');
export const getTimelineContentCommercial = () => wp.get('/acf/v3/options/commercial-milestones');

// HVIs
export const getHVIs = (trackingId, orderNumber) => uni.get(`/moveinfo/${trackingId}/hvis?orderNumber=${orderNumber}`);
export const postHVIs = (trackingId, data) => uni.post(`/moveinfo/${trackingId}/hvis`, data);
export const putHVIs = (trackingId, data) => uni.put(`/moveinfo/${trackingId}/hvis/${data.id}`, data);
export const getHviPdf = (trackingId, orderNumber, data) => uni.get(`/forms/hvi/${trackingId}?orderNumber=${orderNumber}`, data);

// Contacts
export const getContacts = (trackingId) => uni.get(`/moveinfo/${trackingId}/agent-contacts`);

// Custom Contacts
export const getCustomContacts = (trackingId) => uni.get(`/moveinfo/${trackingId}/contacts`);
export const postCustomContact = (trackingId, data) => uni.post(`/moveinfo/${trackingId}/contacts`, data);
export const putCustomContact = (trackingId, data) => uni.put(`/moveinfo/${trackingId}/contacts`, data);
export const deleteCustomContact = (trackingId, contactId) => uni.delete(`moveinfo/${trackingId}/contacts/${contactId}`);

// Checklist
export const getChecklistItems = (trackingId) => uni.get(`/moveinfo/${trackingId}/tasks`);
export const postChecklistItem = (trackingId, data) => uni.post(`/moveinfo/${trackingId}/tasks`, data);
export const putChecklistItem = (trackingId, taskId, data) => uni.put(`/moveinfo/${trackingId}/tasks/${taskId}`, data);
export const deleteChecklistItem = (trackingId, id) => uni.delete(`/moveinfo/${trackingId}/tasks/${id}`);

//Checklist Bulk Operations
export const putChecklistBulkComplete = (trackingId, data) => uni.put(`/moveinfo/${trackingId}/tasks/bulkComplete`, data);
export const deleteChecklistBulkDelete = (trackingId, data) => uni.delete(`/moveinfo/${trackingId}/tasks/bulkDelete`, { data: data });

export const getChecklistContent = () => wp.get(`/acf/v3/options/checklist`);
export const putChecklistContent = () => uni.put('/');

// Main Navigation - Residential
export const getResidentialMenu = () => wp.get(`/acf/v3/options/residential-menus`);

// Dashboard
export const getDashboardContent = () => wp.get(`/acf/v3/options/dashboard`);

// Contacts
export const getContactsContent = () => wp.get(`/acf/v3/options/contacts`);

// HVI
export const getHviContent = () => wp.get(`/acf/v3/options/high-value-inventory`);

// Payments
export const getPaymentContent = () => wp.get(`/acf/v3/options/payments-and-agreements`);

// Partners
export const getPartnersContent = () => wp.get(`/acf/v3/options/partnerships`);

export const getUGServicesContent = () => wp.get(`/acf/v3/options/value-added-services`);
