import partnershipsContentMiddleware from 'middleware/wordpress/partnershipsContent';
import { STORE_PARTNERSHIPS_CONTENT } from '../../actions/wordpress/partnershipsContent';

// Reducer
export const partnershipsContent = (state = {}, action) => {
  if (action.type === STORE_PARTNERSHIPS_CONTENT) {
    return {
      data: partnershipsContentMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'PARTNERSHIPS_CONTENT_ERROR') {
    return {
      data: partnershipsContentMiddleware(action.data),
      error: true,
    }
  }

  return state;
};