import hviContentMiddleware from 'middleware/wordpress/hviContent';

// Reducer
export const hviContent = (state = {}, action) => {
    //Clears Page Data
    if (action.type === 'CLEAR_HVI_CONTENT') {
        return {
            data: null,
            error: false,
        };
    }

    // Sets Page Data
    if (action.type === 'STORE_HVI_CONTENT') {
        return {
            data: hviContentMiddleware(action.data),
            error: false,
        };
    }

    // Handles Error
    if (action.type === 'HVI_CONTENT_ERROR') {
        return {
            data: hviContentMiddleware(action.data),
            error: true,
        };
    }

    return state;
};
