import { defaultErrorMessage } from 'atoms/toast';
import { getChecklistItems, putChecklistItem, postChecklistItem, deleteChecklistItem, putChecklistBulkComplete, deleteChecklistBulkDelete } from 'lib/apis/api';
import { logError, pushAnalyticsEvent, trackingId } from 'lib/utils';

import { outgoingChecklistItemMiddleware } from 'middleware/api/checklist';

export const CLEAR_CHECKLIST_ITEMS = 'CLEAR_CHECKLIST_ITEMS';

export function clearChecklistItems() {
    return {
        type: CLEAR_CHECKLIST_ITEMS,
    };
}

export const STORE_CHECKLIST_ITEMS = 'STORE_CHECKLIST_ITEMS';

export function storeChecklistItems(data) {
    return {
        type: STORE_CHECKLIST_ITEMS,
        data,
    };
}

export const CHECKLIST_ITEMS_ERROR = 'CHECKLIST_ITEMS_ERROR';

export function checklistItemsError(data) {
    return {
        type: CHECKLIST_ITEMS_ERROR,
        data,
    };
}

export const CHECKLIST_ITEM_REMOVED = 'CHECKLIST_ITEM_REMOVED';

export function checklistItemRemoved(id) {
    return {
        type: CHECKLIST_ITEM_REMOVED,
        data: id,
    };
}

export const CHECKLIST_ITEM_EDITED = 'CHECKLIST_ITEM_EDITED';

export function checklistItemEdited(checklistItem) {
    return {
        type: CHECKLIST_ITEM_EDITED,
        data: checklistItem,
    };
}

export const CHECKLIST_ITEMS_COMPLETED = 'CHECKLIST_ITEMS_COMPLETED';

export function checklistItemsCompleted(ids) {
    return {
        type: CHECKLIST_ITEMS_COMPLETED,
        data: ids,
    };
}

export const CHECKLIST_ITEMS_DELETED = 'CHECKLIST_ITEMS_DELETED';

export function checklistItemsDeleted(ids) {
    return {
        type: CHECKLIST_ITEMS_DELETED,
        data: ids,
    };
}

export function fetchChecklistItems(filters) {
    return function (dispatch, getState) {
        const id = trackingId(getState());
        return getChecklistItems(id, filters)
            .then((response) => {
                return dispatch(storeChecklistItems(response.data));
            })
            .catch(() => {
                dispatch(checklistItemsError(null));
                return Promise.reject();
            });
    };
}

export function createChecklistItem(data, displayAndClearToast) {
    return function (dispatch, getState) {
        const id = trackingId(getState());
        const outgoing = outgoingChecklistItemMiddleware(data);

        return postChecklistItem(id, outgoing)
            .then(() => {
                displayAndClearToast({ type: 'success', message: 'Task Created.' });
                pushAnalyticsEvent({ event: 'customTaskAdded' });
                return dispatch(fetchChecklistItems());
            })
            .catch(() => {
                displayAndClearToast({ type: 'error', message: defaultErrorMessage });
                return Promise.reject();
            });
    };
}

export function updateChecklistItem(data, analyticsEvent, displayAndClearToast) {
    return function (dispatch, getState) {
        const taskId = data.id;

        const outgoing = outgoingChecklistItemMiddleware(data);
        return putChecklistItem(trackingId(getState()), taskId, outgoing)
            .then(() => {
                analyticsEvent && pushAnalyticsEvent(analyticsEvent);
                return dispatch(fetchChecklistItems());
            })
            .catch(() => {
                displayAndClearToast({ type: 'error', message: defaultErrorMessage });
                return Promise.reject();
            });
    };
}

export function stageUpdateChecklistItem(checklistItem, analyticsEvent, displayAndClearToast) {
    return function (dispatch, getState) {
        dispatch(checklistItemEdited(checklistItem));

        const toast = {
            type: 'success',
            message: 'Task Updated.',
            undoOptions: {
                confirmFn: () => dispatch(updateChecklistItem(checklistItem, analyticsEvent, displayAndClearToast)),
                cancelFn: () => dispatch(fetchChecklistItems()),
            },
        };

        displayAndClearToast(toast);
    };
}

export function removeChecklistItem(id, displayAndClearToast) {
    return function (dispatch, getState) {
        return deleteChecklistItem(trackingId(getState()), id)
            .then(() => {
                pushAnalyticsEvent({ event: 'deleteListItem' });
                return dispatch(fetchChecklistItems());
            })
            .catch(() => {
                displayAndClearToast({ type: 'error', message: defaultErrorMessage });
                return Promise.reject();
            });
    };
}

export function stageRemoveChecklistItem(id, analyticsEvent, displayAndClearToast) {
    return function (dispatch) {
        dispatch(checklistItemRemoved(id));
        const toast = {
            type: 'success',
            message: 'Task Removed.',
            undoOptions: {
                confirmFn: () => dispatch(removeChecklistItem(id, analyticsEvent, displayAndClearToast)),
                cancelFn: () => dispatch(fetchChecklistItems()),
            },
        };
        displayAndClearToast(toast);
    };
}

export const checklistBulkComplete = (ids, displayAndClearToast) => {
    return (dispatch, getState) => {
        const tid = trackingId(getState());
        return putChecklistBulkComplete(tid, {
            ids: ids,
        })
            .then(() => {
                pushAnalyticsEvent({ event: 'checklistBulkComplete' });
                return dispatch(fetchChecklistItems());
            })
            .catch((err) => {
                displayAndClearToast({ type: 'error', message: 'An error has occurred. Please try again.' });
                logError(err);
                return Promise.reject();
            });
    };
};

export const stageChecklistBulkComplete = (ids, displayAndClearToast) => {
    return (dispatch) => {
        dispatch(checklistItemsCompleted(ids));
        const toast = {
            type: 'success',
            message: `${ids.length > 1 ? `${ids.length} Tasks were` : '1 Task was'} marked complete`,
            undoOptions: {
                confirmFn: () => dispatch(checklistBulkComplete(ids, displayAndClearToast)),
                cancelFn: () => dispatch(fetchChecklistItems()),
            },
        };
        return displayAndClearToast(toast);
    };
};

export const checklistBulkDelete = (ids, displayAndClearToast) => {
    return (dispatch, getState) => {
        const tid = trackingId(getState());
        return deleteChecklistBulkDelete(tid, { ids: ids })
            .then(() => {
                pushAnalyticsEvent({ event: 'checklistBulkDelete' });
                return dispatch(fetchChecklistItems());
            })
            .catch((err) => {
                displayAndClearToast({ type: 'error', message: 'An error has occurred. Please try again.' });
                logError(err);
                return Promise.reject();
            });
    };
};

export const stageChecklistBulkDelete = (ids, displayAndClearToast) => {
    return (dispatch) => {
        dispatch(checklistItemsDeleted(ids));
        const toast = {
            type: 'success',
            message: `${ids.length > 1 ? `${ids.length} Tasks were` : '1 Task was'} deleted`,
            undoOptions: {
                confirmFn: () => dispatch(checklistBulkDelete(ids, displayAndClearToast)),
                cancelFn: () => dispatch(fetchChecklistItems()),
            },
        };
        displayAndClearToast(toast);
    };
};
