// Reducer
export const ccInfo = (state = {}, action) => {
    if (action.type === 'CLEAR_CC_INFO') {
        return {
            data: null,
            error: false,
        };
    }

    if (action.type === 'STORE_CC_INFO') {
        return {
            data: action.data,
            error: false,
        };
    }

    if (action.type === 'CC_INFO_ERROR') {
        return {
            data: null,
            error: true,
        };
    }

    return state;
};
