import * as R from 'ramda';

import { toUniDate, fromUniDate } from 'lib/utils';
import { ChecklistItemStatus } from 'types/checklist';

const taskLens = R.lensProp('tasks');

// Transform tasks to format used by the UI
export const checklistItemsMiddleware = (data) => {
    const dateLens = R.lensProp('dueDate');
    const displayDateLens = R.lensProp('displayDueDate');

    if (data) {
        const formatted = R.map((task) => {
            const d = fromUniDate(R.view(dateLens, task));
            const displayDate = fromUniDate(R.view(displayDateLens, task));
            return {
                id: task.id,
                name: task.name,
                date: d,
                displayDate: displayDate,
                dueInWeeks: task.dueInWeeks,
                status: task.status,
                completed: task.status === ChecklistItemStatus.COMPLETED,
                notes: task.notes,
                description: task.description,
                custom: !task.seeded,
                reloaded: task.reloaded,
                vendor: task.vendor,
            };
        }, R.view(taskLens, data));
        return R.set(taskLens, formatted, data);
    }
};

// Transform a task to the format used by the API
export const outgoingChecklistItemMiddleware = (data) => {
    const dateLens = R.lensProp('date');
    const date = toUniDate(R.view(dateLens, data));

    return {
        ...data,
        status: data.completed ? ChecklistItemStatus.COMPLETED : ChecklistItemStatus.REVIEW,
        dueDate: date,
    };
};
