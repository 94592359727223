import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { checklistContent } from './reducers/wordpress/checklistContentReducer';
import { mobileMenuDisplay } from './reducers/display/mobileMenuDisplayReducer';
import { checklistDisplay } from './reducers/display/checklistDisplayReducer';

import { userProfile } from './reducers/api/userProfileReducer';
import { checklistItems } from './reducers/api/checklistItemsReducer';
import { hvis } from './reducers/api/hvisReducer';
import { csErrors } from './reducers/api/csErrors';
import { ccInfo } from './reducers/api/ccInfo';

import { hardCodedResidentialMenu, residentialMenu } from './reducers/wordpress/residentialMenuReducer';
import { dashboardContent } from './reducers/wordpress/dashboardContentReducer';
import { contactsContent } from './reducers/wordpress/contactsContentReducer';
import { partnershipsContent } from './reducers/wordpress/partnershipsContentReducer';
import { hviContent } from './reducers/wordpress/hviContentReducer';
import { paymentContent } from './reducers/wordpress/paymentContentReducer';

import { timelineContent } from './reducers/wordpress/timelineContentReducer';
import pwaPrompt from './reducers/display/pwaPromptReducer';

import { RESET_MOVE } from './actions/api/moveInfo';
import { drawerInitialState, drawer } from './reducers/display/drawerReducer';
import preferences, { preferencesDefaultState } from './reducers/api/preferences';
import { tour } from './reducers/display/tourReducer';

const initialState = {
    // User Profile
    userProfile: {
        data: null,
        error: false,
    },

    // Account Settings
    // Checklist
    checklistContent: {
        data: {
            edit_field_labels: {
                task_name: 'Task Name',
                due_date: 'Due Date',
                notes: 'Notes',
                save_cta: 'Save Changes',
            },
            add_view_heading: 'New Task',
            default_view_heading: 'Checklist',
            edit_view_heading: 'Task Details',
        },
        error: false,
    },

    checklistItems: {
        data: null,
        error: false,
    },

    // Display
    checklistDisplay: false,

    tour: {
        open: false,
        steps: [],
    },

    // Mobile Menu
    mobileMenuDisplay: false,

    // Residential Menu
    residentialMenu: {
        data: hardCodedResidentialMenu,
        error: false,
    },
    nationalMenu: {
        data: {
            menu_item: [
                {
                    menu_item_label: 'Dashboard',
                    menu_item_type: 'app-route',
                    menu_item_slug: '/dashboard',
                    icon_default: 'home',
                },
                {
                    menu_item_label: 'Contacts',
                    menu_item_type: 'app-route',
                    menu_item_slug: '/contacts',
                    icon_default: 'contacts',
                },
            ],
            sign_out_link: {
                menu_item_label: 'Sign Out',
                icon_default: 'exit_to_app',
            },
            account_settings_link: {
                menu_item_label: 'Account Settings',
                menu_item_slug: '/account-settings',
                icon_default: 'settings',
            },
        },
    },

    // HVIs
    hvis: {
        data: null,
        error: null,
    },

    // Dashboard Content
    dashboardContent: {
        data: null,
        error: null,
    },

    // Contacts Content
    contactsContent: {
        data: null,
        error: null,
    },

    // HVI Content
    hviContent: {
        data: null,
        error: null,
    },

    // Payment Content
    paymentContent: {
        data: null,
        error: null,
    },

    // Timeline Content
    timelineContent: {
        data: null,
        error: null,
    },

    // Handle Cybersource Errors
    csErrors: 0,

    // CC info for display screen
    ccInfo: {
        data: null,
        error: null,
    },
    pwaPrompt: {
        showPrompt: false,
    },
    drawer: drawerInitialState,

    preferences: preferencesDefaultState,
};

// Combine all reducers.
const appReducer = combineReducers({
    userProfile,
    checklistDisplay,
    tour,
    checklistContent,
    checklistItems,
    mobileMenuDisplay,
    residentialMenu,
    hvis,
    dashboardContent,
    contactsContent,
    partnershipsContent,
    hviContent,
    paymentContent,
    timelineContent,
    csErrors,
    ccInfo,
    pwaPrompt,
    drawer,
    preferences,
    // TODO extract if this is too ugly
    nationalMenu: (state = {}, action) => state,
});
const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === RESET_MOVE) state = initialState;
    return appReducer(state, action);
};

const getReduxEnhancers = () => {
    const isProdKeycloak = process.env.KEYCLOAK_ENV === 'prod' ? true : false;
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return isProdKeycloak ? applyMiddleware(thunk) : composeEnhancers(applyMiddleware(thunk));
};

function configureStore() {
    return createStore(rootReducer, initialState, getReduxEnhancers());
}

const store = configureStore();
export default store;
