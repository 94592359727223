import { getPartnersContent } from 'lib/apis/api';
import { logError } from '../../../lib/utils';
export const STORE_PARTNERSHIPS_CONTENT = 'STORE_PARTNERSHIPS_CONTENT';
export function storePartnershipsContent(data) {
    return {
        type: STORE_PARTNERSHIPS_CONTENT,
        data,
    };
}

export const PARTNERSHIPS_CONTENT_ERROR = 'PARTNERSHIPS_CONTENT_ERROR';
export function PartnershipsContentError(data) {
    return {
        type: PARTNERSHIPS_CONTENT_ERROR,
        data,
    };
}
export function fetchPartnershipsContent() {
    return function (dispatch) {
        return getPartnersContent()
            .then((response) => {
                return dispatch(storePartnershipsContent(response.data));
            })
            .catch((err) => {
                logError(err);
                return dispatch(PartnershipsContentError(null));
            });
    };
}
