export default (response) => {
    if (response && response.acf) {
        return response.acf;
    } else {
        return {
            banner: {
                welcome_copy: 'Welcome',
                sub_heading: 'Your MyUnited Move Portal',
                banner_content:
                    'This is where you are in your move process. You can also view your upcoming milestones as well as anything you have completed in the past.',
                banner_content_v2:
                    "<p>Welcome to your move portal, a go-to hub for move details, move management and moving timelines to simplify and streamline the process.</p><p>Here, you'll find:</p><ul><li>Easy online booking</li><li>Dynamic move milestone timeline</li><li>Interactive moving checklist</li><li>Helpful moving resources</li><li>Key moving contacts list</li></ul><p>Ready to get started?</p>",
                full_timeline_trigger_label: 'View Full Timeline',
            },
            move_details_widget_title: 'Your Move',
            services_included_widget_title: 'Services Included',
        };
    }
};
