import * as R from 'ramda';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { USStates } from 'lib/enums';
import { QuoteType } from 'types/quotes';

export const brand = process.env.THEME === 'mayflower' ? 'mayflower' : 'united';

// Dates
export const toUniDate = (data) => {
    const d = moment(data, 'M/D/YYYY');
    return d.isValid() ? d.format('YYYY-MM-DD') : '--/--/--';
};
export const fromUniDate = (data) => {
    const d = moment(data, 'YYYY-MM-DD');
    return d.isValid() ? d.format('M/D/YYYY') : '--/--/--';
};

const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const amountFormat = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

export const formatNumber = (rawNumber) => {
    return rawNumber && !isNaN(rawNumber) ? numberFormat.format(rawNumber) : '$0.00';
};

export const formatAmount = (rawNumber) => {
    return rawNumber && !isNaN(rawNumber) ? amountFormat.format(rawNumber) : '0.00';
};

export const formatPhone = (value) => {
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

export const isValidEmail = (email) => {
    return /[^@]+@[^.]+\..+/.test(email);
};

export const reformatDate = (date, format = 'MMMM D, YYYY') => {
    return moment(date).format(format);
};

const today = moment();
const twoDays = today.clone().add(3, 'days').startOf('day');

export const isWithinTwoDays = (momentDate) => {
    return momentDate.isBefore(twoDays);
};

export const getEacceptanceSummary = (quoteId, eAcceptances) => {
    if (eAcceptances != null) {
        return eAcceptances.find((it) => it?.quoteId === quoteId);
    }
};

export const getStatus = (quoteId, eAcceptances) => {
    const eAcceptance = getEacceptanceSummary(quoteId, eAcceptances);

    return eAcceptance && eAcceptance.quoteStatus;
};

export const isPaymentRequired = (quote) => !(quote && (quote.type === 'One-Time-National' || quote.type === 'One-Time-National-Auto'));

export const getDisplayStatus = (quote, eAcceptances) => {
    if (!quote) return;
    const eAcceptance = getEacceptanceSummary(quote.id, eAcceptances);

    if (eAcceptance) {
        return isPaymentRequired(quote) && (eAcceptance.quoteStatus === 'BOOKED' || eAcceptance.quoteStatus === 'PENDING')
            ? 'ACTION REQUIRED'
            : eAcceptance.quoteStatus;
    }
};

export const getEacceptanceByFormTypeCode = (eacceptanceSummary, formTypeCode) => {
    return (
        eacceptanceSummary &&
        eacceptanceSummary.eacceptances &&
        eacceptanceSummary.eacceptances.find((eAcceptance) => eAcceptance.formTypeCode.match(formTypeCode))
    );
};

export const getEstimateTitle = (quote) => {
    const type = quote && quote.type;

    switch (type) {
        case QuoteType.FULL_SERVICE:
        case QuoteType.ONE_TIME_NATIONAL:
            return 'Household Goods Estimate';
        case QuoteType.AUTO:
        case QuoteType.ONE_TIME_NATIONAL_AUTO:
            return 'Auto Estimate';
        default:
            return 'Estimate';
    }
};

export const decapitalize = (s) =>
    typeof s == 'string'
        ? s
              .split(' ')
              .map((str) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`)
              .join(' ')
        : s;

export const resolveAddressDisplay = (city, state, zip) => {
    if (city && state && city !== '' && state !== '') {
        if (city.toUpperCase() === 'UNKNOWN') {
            return zip;
        } else if (state.toUpperCase() === 'UNKNOWN') {
            return zip;
        } else {
            return `${decapitalize(city)}, ${state}`;
        }
    } else if (zip && zip !== '') {
        return zip;
    } else {
        return 'N/A';
    }
};

export const getPrimaryQuote = (moveInfo) => {
    if (moveInfo && moveInfo.quotes && moveInfo.quotes.length) {
        const quoteTypes = moveInfo.quotes.map((quote) => quote.type);

        const hasFullServiceOrOneTime = quoteTypes.includes(QuoteType.FULL_SERVICE) || quoteTypes.includes(QuoteType.ONE_TIME_NATIONAL);
        const hasAutoOrOneTimeAuto = quoteTypes.includes(QuoteType.AUTO) || quoteTypes.includes(QuoteType.ONE_TIME_NATIONAL_AUTO);

        if (hasFullServiceOrOneTime) {
            return moveInfo.quotes.find((q) => q.type === QuoteType.FULL_SERVICE || q.type === QuoteType.ONE_TIME_NATIONAL);
        } else if (hasAutoOrOneTimeAuto) {
            return moveInfo.quotes.find((q) => q.type === QuoteType.AUTO || q.type === QuoteType.ONE_TIME_NATIONAL_AUTO);
        } else {
            return moveInfo.quotes[0];
        }
    }
};

export const trackingId = R.path(['userProfile', 'data', 'trackingId']);
export const customerType = R.path(['userProfile', 'data', 'customer', 'customerType']);
export const userQuotes = R.path(['userProfile', 'data', 'quotes']);
export const fullProfile = R.path(['userProfile', 'data']);
export const hvi = R.path(['hvis', 'data']);
export const fullServiceQuote = R.compose(R.find(R.propEq('type', QuoteType.FULL_SERVICE)), userQuotes);

export const logError = (...errorMessage) => {
    if (errorMessage && errorMessage.length > 0) {
        if (process.env.KEYCLOAK_ENV === 'dev') {
            // eslint-disable-next-line no-console
            console.log('ERROR');
            errorMessage.forEach((m) => {
                // eslint-disable-next-line no-console
                console.log(m);
            });
        } else {
            errorMessage.forEach((m) => {
                if (m.config) {
                    const { baseURL, url, method } = m.config;
                    Sentry.setContext('CONFIG', { baseURL, url, method });
                }

                if (m.response) {
                    const { data } = m.response;
                    Sentry.setContext('RESPONSE', { data });
                }

                Sentry.captureException(m);
            });
        }
    }
};

export const logDebug = (...message) => {
    if (message && message.length > 0 && process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('DEBUG:');
        message.forEach((m) => {
            // eslint-disable-next-line no-console
            console.log(m);
        });
    }
};

export const preventInput = (e, type, onEnter) => {
    e = e || window.event;
    const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;

    if (charCode === 13) {
        onEnter && onEnter();
        return;
    }

    const charStr = String.fromCharCode(charCode);

    switch (type) {
        case 'INPUT_NUMBER':
            if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
            break;
        case 'INPUT_ALPHA_NUMBERIC':
            if (!charStr.match(/^[-\w\s]+$/)) e.preventDefault();
            break;
        case 'INPUT_DATE':
            if (!charStr.match(/^[0-9/]+$/)) e.preventDefault();
            break;
        case 'INPUT_AMOUNT':
            if (!charStr.match(/^[0-9.]+$/)) e.preventDefault();
            break;
        case 'INPUT_ASCII':
            if (!charStr.match(/^[\x20-\x7F]*$/)) e.preventDefault();
            break;
        default:
            // NOOP
            break;
    }
};

export const replaceAll = (input, findRegex, replace) => {
    return input.replace(findRegex, replace);
};

export const sanitizePaste = (data) => {
    const expression = new RegExp(/[a-zA-Z$ ]/, 'g');

    if (data && expression.test(data)) {
        return data.replace(expression, '');
    } else return data;
};

export const asciiSanitizePaste = (data) => {
    const expression = new RegExp(/[^\x20-\x7F]/, 'g');
    return data && expression.test(data) ? data.replace(expression, '') : data;
};

export const getStateTimezone = (state) => {
    if (state) {
        const usStates = Object.entries(USStates).map((stateAbbreviation) => stateAbbreviation[1]);
        const foundState = usStates.find((st) => st.abbreviation.toLowerCase() === state.toLowerCase());
        if (foundState) {
            return foundState.timezone;
        }
    }
    return 'America/Chicago';
};

export const getThemePrettyName = () => {
    return process.env.THEME === 'mayflower' ? 'Mayflower' : 'United';
};

export const getThemePrettyFullName = () => {
    return process.env.THEME === 'mayflower' ? 'Mayflower' : 'United Van Lines';
};

export const pushAnalyticsEvent = (obj) => {
    window.dataLayer.push(obj);
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('published analytics event', obj);
    }
};

const errorFallback = 'NaN';
export const getPercentPaidFromTotal = (total, balanceDue) => {
    try {
        if (typeof total !== 'number' || typeof balanceDue !== 'number' || balanceDue > total) {
            return errorFallback;
        }

        const percent = (1 - balanceDue / total) * 100;

        return isNaN(percent) ? errorFallback : Number(percent.toFixed(2));
    } catch (e) {
        logError(e);
        return errorFallback;
    }
};

export const getNonProdEnvShortName = () => {
    switch (process.env.KEYCLOAK_ENV) {
        case 'dev':
            return 'dev';
        case 'stage':
            return 'stg';
        default:
            return '';
    }
};

export const hotjarTrigger = (trigger) => {
    if (window.hj) {
        window.hj('trigger', trigger);
        logDebug(`hotjar trigger: ${trigger}`);
    }
};

export const closeHotjarFeedback = () => {
    if (window.hj) {
        window.hj('stateChange', '/');
    }
};

export const toggleScrollLock = (enabled) => {
    document.body.style.position = enabled ? 'fixed' : '';
};
