import { getTimeline, getTimelineContentResidential, getTimelineContentCommercial } from 'lib/apis/api';

import { logError, trackingId } from 'lib/utils';
import { customerType } from 'lib/utils';
import { CustomerType } from 'types/moves';

export const CLEAR_TIMELINE_CONTENT = 'CLEAR_TIMELINE_CONTENT';
export const STORE_TIMELINE_CONTENT = 'STORE_TIMELINE_CONTENT';
export const TIMELINE_CONTENT_ERROR = 'TIMELINE_CONTENT_ERROR';
export const FETCHING_TIMELINE_CONTENT = 'FETCHING_TIMELINE_CONTENT'

export const clearTimelineContent = () => {
    return {
        type: CLEAR_TIMELINE_CONTENT,
    };
}

export const storeTimelineContent = (status, content) => {
    return {
        type: STORE_TIMELINE_CONTENT,
        status,
        content,
    };
}

export const timelineContentError = (data) => {
    return {
        type: TIMELINE_CONTENT_ERROR,
        data,
    };
}

export const fetchTimelineContent = (filters) => {
    return function (dispatch, getState) {
        if (getState().timelineContent.fetching) return;

        const isResidential = customerType(getState()) === CustomerType.HHGD_COD ? true : false;

        const id = trackingId(getState());
        dispatch({ type: FETCHING_TIMELINE_CONTENT});
        return getTimeline(id)
            .then((response) => {
                const timelineStatus = response.data;
                const functionToRun = isResidential ? getTimelineContentResidential() : getTimelineContentCommercial();
                return functionToRun
                    .then((response) => {
                        // Combine status and content
                        const timelineContent = response.data;
                        return dispatch(storeTimelineContent(timelineStatus, timelineContent));
                    })
                    .catch((err) => {
                        logError(err);
                        return dispatch(timelineContentError(null));
                    });
            })
            .catch((err) => {
                logError(err);
                return dispatch(timelineContentError(null));
            });
    };
}
