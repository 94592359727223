import { getMoveInfo } from 'lib/apis/api';
import { logError, trackingId as getTrackingId, pushAnalyticsEvent } from 'lib/utils';

export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export function clearUserProfile() {
    return {
        type: CLEAR_USER_PROFILE,
    };
}

export const STORE_USER_PROFILE = 'STORE_USER_PROFILE';
export function storeUserProfile(data) {
    pushAnalyticsEvent({ dimension5: data.trackingId, dimension6: data.customer && data.customer.customerType });
    return {
        type: STORE_USER_PROFILE,
        data,
    };
}

export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export function userProfileError(data) {
    return {
        type: USER_PROFILE_ERROR,
        data,
    };
}

export const USER_FORBIDDEN_ERROR = 'USER_FORBIDDEN_ERROR';
export function userForbiddenError(data) {
    return {
        type: USER_FORBIDDEN_ERROR,
        data,
    };
}

export const CLIENT_ERROR = 'CLIENT_ERROR';
export function clientError(data) {
    return {
        type: CLIENT_ERROR,
        data,
    };
}

export const RESET_MOVE = 'RESET_MOVE';
export function resetMoveData() {
    return {
        type: RESET_MOVE,
    };
}

export const fetchMoveInfo = (trackingId) => {
    return async (dispatch) => {
        try {
            const response = await getMoveInfo(trackingId);
            return dispatch(storeUserProfile(response.data));
        } catch (err) {
            logError(err);
            return dispatch(userProfileError(null));
        }
    };
};

export const resetMove = (trackingId, displayAndClearToast, setBackdrop) => (dispatch, getState) => {
    setBackdrop(true);

    const defaultTrackingId = getTrackingId(getState());

    getMoveInfo(trackingId)
        .then(({ data }) => {
            dispatch(resetMoveData());
            dispatch(storeUserProfile(data));
        })
        .catch((error) => {
            logError(error);
            if (error.response.status === 500) {
                dispatch(userProfileError(null));
            } else if (error.response.status === 403) {
                displayAndClearToast({
                    type: 'error',
                    message: 'Oops! Try selecting a different move.',
                });
                dispatch(fetchMoveInfo(defaultTrackingId));
            }
        })
        .finally((_) => {
            setBackdrop(false);
        });
};

export const fetchMostRecentMoveInfo = () => {
    return async (dispatch) => {
        try {
            const response = await getMoveInfo();
            return dispatch(storeUserProfile(response.data));
        } catch (err) {
            if (err && err.response && err.response.status === 403) {
                return dispatch(userForbiddenError());
            } else if (err && err.response && err.response.status >= 400 && err.response.status < 500) {
                return dispatch(clientError(err));
            }
            logError(err);
            return dispatch(userProfileError(null));
        }
    };
};
