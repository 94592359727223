import { brand } from '../lib/utils';
import React from 'react';

const LogoScreen: React.FC = () => (
    <div
        style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <img
            alt='Brand logo'
            src={`${process.env.PUBLIC_URL}/assets/logo-color-${brand}.svg`}
            style={{
                objectFit: 'contain',
                margin: 'auto',
                height: '100vh',
                width: '90vw',
                maxWidth: '600px',
            }}
        />
    </div>
);

export default LogoScreen;
