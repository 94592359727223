import { checklistSteps } from '../../tours/checklist';
import { ReactourStep } from 'reactour';

export interface TourType {
    open: boolean;
    steps: ReactourStep[];
}

export const tour = (state = {}, action: { type: string }) => {
    switch (action.type) {
        case 'SHOW_CHECKLIST_TOUR':
            return {
                open: true,
                steps: checklistSteps,
            };
        case 'COMPLETED_TOUR':
            return {
                open: false,
                steps: [],
            };
        default:
            return state;
    }
};
