import * as R from 'ramda';

import timelineContentMiddleware from 'middleware/wordpress/timelineContent';
import {
    CLEAR_TIMELINE_CONTENT,
    FETCHING_TIMELINE_CONTENT,
    STORE_TIMELINE_CONTENT,
    TIMELINE_CONTENT_ERROR
} from "../../actions/wordpress/timelineContent";

const getContentForStatus = (status, type, additionalInfo, data) => {
    const st = status && status.toLowerCase() !== 'error' ? status.toLowerCase() : 'incomplete';
    return {
        status,
        type,
        additionalInfo,
        icon_default: R.prop(`milestone_${st}_icon_default`, data),
        icon_url_color: R.prop(`milestone_${st}_icon_url_color`, data),
        icon_url_white: R.prop(`milestone_${st}_icon_url_white`, data),
        milestone_cta: R.prop(`milestone_${st}_milestone_cta`, data),
        milestone_description: R.prop(`milestone_${st}_milestone_description`, data),
        milestone_title: R.prop(`milestone_${st}_milestone_title`, data),
    };
};

// Reducer
export const timelineContent = (state = {}, action) => {
    switch (action.type) {
        case CLEAR_TIMELINE_CONTENT:
            return {
                data: null,
                error: false,
            };
        case STORE_TIMELINE_CONTENT: {
            const status = R.prop('status', action);
            const content = R.path(['content', 'acf', 'milestone'], action);
            if (status && content) {
                const combined = R.zip(status, content);
                const data = R.map((item) => {
                    const s = R.prop('status', item[0]);
                    const t = R.prop('type', item[0]);
                    const ai = R.prop('additionalInfo', item[0]);
                    return getContentForStatus(s, t, ai, item[1]);
                }, combined);

                return {
                    data: timelineContentMiddleware(data),
                    error: false,
                };
            }
            return {
                data: null,
                error: true,
            };
        }
        case FETCHING_TIMELINE_CONTENT:
            return {
                ...state,
                fetching: true,
            };
        case TIMELINE_CONTENT_ERROR:
            return {
                data: null,
                error: true,
            };
        default:
             return state
    }
};
