export const wpUrl = () => {
    switch (process.env.API_ENV) {
        case 'local':
            return process.env.THEME === 'united' ? 'https://devmy.unitedvanlines.com/wp-json' : 'https://devmy.mayflower.com/wp-json';
        case 'dev':
            return process.env.THEME === 'united' ? 'https://devmy.unitedvanlines.com/wp-json' : 'https://devmy.mayflower.com/wp-json';
        case 'stage':
            return process.env.THEME === 'united' ? 'https://stgmy.unitedvanlines.com/wp-json' : 'https://stgmy.mayflower.com/wp-json';
        case 'prod':
            return process.env.THEME === 'united' ? 'https://my.unitedvanlines.com/wp-json' : 'https://my.mayflower.com/wp-json';
        default:
            return 'http://localhost/wp-json';
    }
};
