import moment from 'moment';

export interface ChecklistItem {
    id: string;
    name: string;
    date: string;
    displayDate?: string;
    dueInWeeks?: string;
    status: ChecklistItemStatus;
    completed: boolean;
    notes?: string;
    description?: string;
    custom: boolean;
    reloaded: boolean;
}

export interface EditedChecklistItem {
    id?: string;
    name?: string;
    date?: moment.Moment | null;
    notes?: string;
    completed?: boolean;
    custom?: boolean;
    description?: string;
    vendor?: string;
}

export enum ChecklistItemStatus {
    COMPLETED = 'COMPLETED',
    REVIEW = 'REVIEW',
}
