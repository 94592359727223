import { Address } from './moves';

export interface Quote {
    agentNumber: string;
    autoDetails?: QuoteAutoDetails;
    companyCode: string;
    destinationAddress?: Address;
    estimateNumber: string;
    formattedOrderNumber?: string;
    id: string;
    loadDay?: string;
    miles?: number;
    orderId?: QuoteOrderId;
    originAddress?: Address;
    paymentType?: PaymentType;
    price?: number;
    status: string;
    type: QuoteType;
    weight?: number;
}

export enum QuoteType {
    FULL_SERVICE = 'Full-Service',
    AUTO = 'Auto',
    ONE_TIME_NATIONAL = 'One-Time-National',
    ONE_TIME_NATIONAL_AUTO = 'One-Time-National-Auto',
    NATIONAL = 'National',
    OTHER = 'Other',
}

export interface QuoteAutoDetails {
    makeAuto?: string;
    modlAuto?: string;
    wghtAuto: number;
    yearAuto: number;
}

export interface QuoteOrderId {
    agentNum?: string;
    companyCode?: string;
    orderNum?: string;
    orderYear?: string;
    ovflCode?: string;
    psCode?: string;
    shCode?: string;
}

export enum PaymentType {
    CASHIERS_CHECK = 'CASHIERS_CHECK',
    CREDIT_CARD = 'CREDIT_CARD',
    UNKNOWN = 'UNKNOWN',
}
