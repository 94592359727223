import { atom } from 'jotai';
import { ToastConfig } from 'types/toast';

export const toastAtom = atom<ToastConfig | undefined>(undefined);
export const displayAndClearToastAtom = atom(null, (get, set, payload: ToastConfig) => {
    set(toastAtom, payload);
    setTimeout(() => {
        set(toastAtom, undefined);
    }, 3000);
});
export const defaultErrorMessage = 'An error has occurred. Please try again.';

export const backdropAtom = atom(false);
