//import residentialMenuMiddleware from 'middleware/wordpress/residentialMenu'
export const hardCodedResidentialMenu = {
    menu_item: [
        {
            menu_item_label: 'Dashboard',
            menu_item_type: 'app-route',
            menu_item_slug: '/dashboard',
            icon_default: 'home',
        },
        {
            menu_item_label: 'Payment & Agreements',
            menu_item_type: 'app-route',
            menu_item_slug: '/payment',
            icon_default: 'payment',
        },
        {
            menu_item_label: 'High Value Inventory',
            menu_item_type: 'app-route',
            menu_item_slug: '/high-value-inventory',
            icon_default: 'format_list_bulleted',
        },
        {
            menu_item_label: 'Contacts',
            menu_item_type: 'app-route',
            menu_item_slug: '/contacts',
            icon_default: 'contacts',
        },
        // {
        //     menu_item_label: 'Partner Offers',
        //     menu_item_type: 'app-route',
        //     menu_item_slug: '/partnerships',
        //     icon_default: 'people',
        // },
        {
            menu_item_label: 'Services',
            menu_item_type: 'app-route',
            menu_item_slug: '/services',
            icon_default: 'style',
        },
    ],
    sign_out_link: {
        menu_item_label: 'Sign Out',
        icon_default: 'exit_to_app',
    },
    account_settings_link: {
        menu_item_label: 'Account Settings',
        menu_item_slug: '/account-settings',
        icon_default: 'settings',
    },
};

// Reducer
export const residentialMenu = (state = {}, action) => {
    //Clears Page Data
    if (action.type === 'CLEAR_RESIDENTIAL_MENU') {
        return {
            data: hardCodedResidentialMenu,
            error: false,
        };
    }

    // Sets Page Data
    if (action.type === 'STORE_RESIDENTIAL_MENU') {
        return {
            // data: residentialMenuMiddleware(action.data),
            data: hardCodedResidentialMenu,
            error: false,
        };
    }

    // Handles Error
    if (action.type === 'RESIDENTIAL_MENU_ERROR') {
        return {
            // data: residentialMenuMiddleware(action.data),
            data: hardCodedResidentialMenu,
            error: true,
        };
    }

    return state;
};
