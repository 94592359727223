export default (response) => {
    if (response && response.acf) {
        return response.acf;
    } else {
        return {
            edit_field_labels: {
                task_name: 'Task Name',
                due_date: 'Due Date',
                notes: 'Notes',
                save_cta: 'Save Changes',
            },
            add_view_heading: 'New Task',
            default_view_heading: 'Checklist',
            edit_view_heading: 'Task Details',
        };
    }
};
