import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { brand } from 'lib/utils';

interface Props {
    buttonProps?: ButtonProps;
    label: string;
    isLoading?: boolean;
}

const isUnited = brand === 'united';
const color = isUnited ? '#005aab' : '#258752';
const focusColor = isUnited ? '#072440' : '#196f41';

const BaseButton = withStyles({
    root: {
        '&:hover': {
            transition: 'background-color .2s ease-in-out',
        },
    },
    label: {
        fontFamily: '"Roboto", sans-serif',
    },
    text: {
        textDecoration: 'underline',
        color: color,
        padding: 0,
        backgroundColor: 'transparent',
        '&:hover': {
            color: focusColor,
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '& .MuiButton-label': {
            letterSpacing: 'inherit',
            fontWeight: 'inherit',
            textTransform: 'none',
            fontSize: 14,
        },
    },
    outlined: {
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: color,
            borderColor: color,
            color: 'white',
        },
        '&:focus': {
            backgroundColor: color,
            borderColor: color,
            color: 'white',
        },
        borderColor: color,
        color: color,
    },
    contained: {
        backgroundColor: color,

        '&:hover': {
            backgroundColor: focusColor,
        },
    },
    outlinedSizeSmall: {
        color: 'black',
        padding: '3px 10px',
        fontSize: '12px',
        lineHeight: 1.5,
        margin: '10px 5px 10px 5px',
        width: '85%',
    },
    containedSizeSmall: {
        padding: '3px 10px',
        fontSize: '12px',
        lineHeight: 1.5,
        margin: '10px 5px 10px 5px',
        width: '85%',
    },
    sizeLarge: {
        minWidth: 175,
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 1,
        width: 'fit-content',
        padding: '14px 20px',
        margin: '10px 0px',
    },
    fullWidth: {
        width: '100%',
    },
    textSizeLarge: {},
    textSizeSmall: {
        transition: 'color .01s',
        textDecoration: 'none',
        '& .MuiButton-label': {
            fontSize: 12,
        },
    },
    containedSizeLarge: {},
    outlinedSizeLarge: {},
})(Button);

const themes = {
    united: createStyles({
        textSizeLarge: {
            textTransform: 'uppercase',
            letterSpacing: 2,
        },
        containedSizeLarge: {
            textTransform: 'uppercase',
            letterSpacing: 2,
        },
        outlinedSizeLarge: {
            textTransform: 'uppercase',
            letterSpacing: 2,
        },
        outlinedSizeSmall: {
            textTransform: 'none',
        },
        containedSizeSmall: {
            textTransform: 'none',
        },
    }),
    mayflower: createStyles({
        textSizeLarge: {
            textTransform: 'none',
            letterSpacing: 'initial',
        },
        containedSizeLarge: {
            textTransform: 'none',
            letterSpacing: 'initial',
        },
        outlinedSizeLarge: {
            textTransform: 'none',
            letterSpacing: 'initial',
        },
    }),
};

const useStyles = makeStyles({
    bounce: {
        animation: 'bounceScale 2s ease-in-out infinite',
        width: '12px',
        height: '12px',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        margin: '2px 3px',
        borderRadius: '100%',
    },
    first: {
        animationDelay: '-.66s',
    },
    second: {
        animationDelay: '-.33s',
    },
});

const ThemedButton = withStyles(themes[brand])(BaseButton);

const CXButton: React.FC<Props> = ({ buttonProps, label, children, isLoading = false }) => {
    const classes = useStyles();
    return (
        <ThemedButton
            disableRipple={true}
            disableElevation={true}
            variant='contained'
            color='primary'
            aria-label={label}
            {...buttonProps}
            disabled={isLoading || buttonProps?.disabled}
        >
            {isLoading ? (
                <>
                    <div className={`${classes.bounce} ${classes.first}`} />
                    <div className={`${classes.bounce} ${classes.second}`} />
                    <div className={`${classes.bounce}`} />
                </>
            ) : children ? (
                children
            ) : (
                label
            )}
        </ThemedButton>
    );
};

export default CXButton;
