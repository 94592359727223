import { isEmpty } from 'ramda';
import { getThemePrettyFullName } from '../../lib/utils';

export default (response) => {
    if (response && !isEmpty(response.acf)) {
        return response.acf;
    } else {
        return {
            main_heading: 'Our Partners',
            subheading:
                "Thanks for your interest in our partners' products and services." +
                " You're eligible for special promotions, such as complimentary gear, discounted subscriptions and more," +
                ` because you moved with ${getThemePrettyFullName()}.`,
            deal_card: [
                {
                    deal_card_sequence: 1,
                    deal_card_service_icon: false,
                    deal_card_service_provider: 'Liberty Mutual',
                    deal_card_main_heading: 'Home & Auto Insurance',
                    deal_card_main_subheading: 'Receive a quote from Liberty Mutual in less than five minutes and take advantage of special savings.',
                    deal_card_partner_logo: `${process.env.PUBLIC_URL}/partnerships/Liberty_Mutual.svg`,
                    deal_card_cta_destination:
                        'https://www.libertymutual.com/multi-online-quotes?selectedOpt=home&src=aff_5ba_h_0136968_unitedvanlines&MM_webID=0000124661',
                    deal_card_cta: 'View Offer',
                    deal_card_ga_event_label: 'Liberty Mutual',
                },
                {
                    deal_card_sequence: 2,
                    deal_card_service_icon: false,
                    deal_card_service_provider: 'National Broadband',
                    deal_card_main_heading: 'TV & Internet',
                    deal_card_main_subheading:
                        'National Broadband will help get your home move-in ready by setting up your TV, internet, and utilities at no extra cost.',
                    deal_card_partner_logo: `${process.env.PUBLIC_URL}/partnerships/National_Broadband.svg`,
                    deal_card_cta_destination: 'https://nationalbroadband.com/united-van-lines/?ag=8002',
                    deal_card_cta: 'View Offer',
                    deal_card_ga_event_label: 'National broadband',
                },
                {
                    deal_card_sequence: 3,
                    deal_card_service_icon: false,
                    deal_card_service_provider: 'ADT',
                    deal_card_main_heading: 'Pro Installed Home Security',
                    deal_card_main_subheading:
                        'Get reliable security professionally installed and receive a complimentary doorbell camera, free installation, and more.',
                    deal_card_partner_logo: `${process.env.PUBLIC_URL}/partnerships/ADT.svg`,
                    deal_card_cta_destination: 'https://s24alarms.com/unitedvanlinesportal',
                    deal_card_cta: 'View Offer',
                    deal_card_ga_event_label: 'ADT',
                },
                {
                    deal_card_sequence: 4,
                    deal_card_service_icon: false,
                    deal_card_service_provider: 'SimpliSafe',
                    deal_card_main_heading: 'Award Winning Home Security',
                    deal_card_main_subheading:
                        'More than 4 million people trust SimpliSafe to protect their homes. Receive an exclusive discount, a SimpliCam and 2 months of professional monitoring, free!',
                    deal_card_partner_logo: `${process.env.PUBLIC_URL}/partnerships/SimpliSafe.svg`,
                    deal_card_cta_destination: 'https://simplisafe.com/homeowners?utm_medium=Partnerdigital&utm_source=unitedvl&utm_campaign=unitedmp',
                    deal_card_cta: 'View Offer',
                    deal_card_ga_event_label: 'SimpliSafe',
                },
            ],
        };
    }
};
