// Reducer
export const userProfile = (
    state = {
        data: null,
        error: false,
        loaded: false,
        status: '',
    },
    action,
) => {
    //Clears Page Data
    if (action.type === 'ClEAR_USER_PROFILE') {
        return {
            data: null,
            error: false,
            loaded: false,
        };
    }

    // Sets Page Data
    if (action.type === 'STORE_USER_PROFILE') {
        return {
            data: action.data,
            error: false,
            loaded: true,
        };
    }

    // Handles Error
    if (action.type === 'USER_PROFILE_ERROR') {
        return {
            data: action.data,
            error: true,
            loaded: true,
        };
    }

    if (action.type === 'USER_FORBIDDEN_ERROR') {
        return {
            data: action.data,
            error: false,
            loaded: true,
            status: 'FORBIDDEN',
        };
    }

    if (action.type === 'CLIENT_ERROR') {
        // action.data - contains error message if we want to display
        return {
            data: null,
            error: false,
            loaded: true,
            status: 'CLIENT_ERROR',
        };
    }

    return state;
};
