import paymentContentMiddleware from 'middleware/wordpress/paymentContent';

// Reducer
export const paymentContent = (state = {}, action) => {
    //Clears Page Data
    if (action.type === 'CLEAR_PAYMENT_CONTENT') {
        return {
            data: null,
            error: false,
        };
    }

    // Sets Page Data
    if (action.type === 'STORE_PAYMENT_CONTENT') {
        return {
            data: paymentContentMiddleware(action.data),
            error: false,
        };
    }

    // Handles Error
    if (action.type === 'PAYMENT_CONTENT_ERROR') {
        return {
            data: paymentContentMiddleware(action.data),
            error: true,
        };
    }

    return state;
};
