import {Country} from "../../../types/cards";

export type UserPreferencesSavedCard = {
    id: string;
    lastFourCardNumber: string;
    firstName: string;
    lastName: string;
    amount: number;
    token: string;
    address: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: string;
        country: Country;
    };
    cardType: string;
    expirationYear: string;
    expirationMonth: string;
};

export interface PreferencesState {
    cards: Array<UserPreferencesSavedCard>;
}

interface ReduxAction {
    type: string;
    payload: Array<UserPreferencesSavedCard>;
}

export const preferencesDefaultState: PreferencesState = {
    cards: [],
};

export default (state: PreferencesState = preferencesDefaultState, action: ReduxAction) => {
    switch (action.type) {
        case 'STORE_SAVED_CARDS':
            return { ...state, cards: action.payload };
        default:
            return state;
    }
};
