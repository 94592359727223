import { Quote } from './quotes';

export interface MoveInfo {
    additionalMove: AdditionalMove[];
    approxLoadDate?: string;
    assignedEstimatorUserCode?: string;
    authCode?: string;
    brand: string;
    createdDate: string;
    customer: Customer;
    destinationAddress: Address;
    originAddress: Address;
    quotes: Quote[];
    status?: string;
    surveyTimestamp?: string;
    trackingId: number;
    additionalMoves: AdditionalMove[];
    hhgdLoadDate?: string;
    firstLogin: boolean;
    surveySchedulerTrackingId?: string;
}

export interface AdditionalMove {
    destinationCity?: string;
    destinationState?: string;
    destinationZip?: string;
    originCity?: string;
    originState?: string;
    originZip?: string;
    trackingId: number;
    moveDate?: string;
}

export interface Customer {
    customerType: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export enum CustomerType {
    HHGD_COD = 'HHGD COD',
    NATIONAL_ACCOUNT = 'National Account',
}

export interface Address {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    postalCode?: string;
    state?: string;
}
