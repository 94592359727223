export default (state = { showPrompt: false }, action) => {
    switch (action.type) {
        case 'BOOKING_COMPLETED':
        case 'FREQUENT_VISITOR_RETURNED':
            return { ...state, showPrompt: true };
        case 'PROMPT_SHOWN':
            return { ...state, showPrompt: false };
        case 'VISIT_COUNTED':
            return { ...state, visitCounted: true };
        default:
            return state;
    }
};
