// Reducer
export const checklistDisplay = (state = {}, action) => {
    if (action.type === 'SHOW_CHECKLIST') {
        return true;
    }

    if (action.type === 'HIDE_CHECKLIST') {
        return false;
    }

    if (action.type === 'TOGGLE_CHECKLIST') {
        return state ? false : true;
    }

    return state;
};
