import { ReactourStep } from 'reactour';
import React from 'react';
import CXButton from '../../components/CXComponents/CXButton';
import unitedSvg from 'assets/onboarding/United/checklist.svg';
import mayflowerSvg from 'assets/onboarding/Mayflower/checklist.svg';
import { brand } from '../../lib/utils';

export const checklistSteps: ReactourStep[] = [
    {
        content: (args) => (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'space-evenly',
                    minHeight: '350px',
                }}
            >
                <img
                    style={{
                        height: '64px',
                        width: '64px',
                    }}
                    src={brand === 'united' ? unitedSvg : mayflowerSvg}
                    alt=''
                />
                <span
                    style={{
                        letterSpacing: '2px',
                    }}
                    className='typo_body--xsmall'
                >
                    GET STARTED
                </span>
                <span
                    style={{
                        marginTop: '10px',
                    }}
                    className='typo_heading--h2 typo_font_color'
                >
                    Ultimate Move Checklist
                </span>
                <p className='typo_body--small'>
                    Here you'll find helpful to-do's, curated by our moving experts, to keep you organized during your move. It'll update with each stage of
                    your move journey. Add your own tasks, too!
                </p>
                <CXButton
                    label='TAKE THE TOUR'
                    buttonProps={{
                        onClick: () => args.goTo(args.step),
                    }}
                />
                <CXButton
                    label='Skip'
                    buttonProps={{
                        variant: 'text',
                        size: 'small',
                        onClick: () => args.close(),
                    }}
                />
            </div>
        ),
    },
    {
        selector: '#checklist-add-button',
        content: () => (
            <>
                <span className='checklist-tour-header'>Add your own items</span>
                <p className='typo_body--small checklist-tour-body'>Keep track of all your to-do's, by adding in custom checklist items.</p>
            </>
        ),
    },
    {
        selector: 'div[class="checklist_item"]',
        highlightedSelectors: ['#checklist-progress'],
        content: () => (
            <>
                <span className='checklist-tour-header'>Mark complete</span>
                <p className='typo_body--small checklist-tour-body'>Stay on track by marking items complete as you go and watch the progress bar fill up.</p>
            </>
        ),
    },
    {
        selector: '#checklist-filter',
        content: () => (
            <>
                <span className='checklist-tour-header'>Filter by time</span>
                <p className='typo_body--small checklist-tour-body'>
                    We've done the hard work for you, by splitting up tasks by weeks out from your move. Click through to see what you need to do each week!
                </p>
            </>
        ),
    },
    {
        selector: '#checklist-items  div:nth-of-type(4) .checklist_item span',
        highlightedSelectors: ['#checklist-items  div:nth-of-type(4) .checklist_item div'],
        content: () => (
            <>
                <span className='checklist-tour-header'>Explore details</span>
                <p className='typo_body--small checklist-tour-body'>Click into each item for more information. We've even included helpful links on some!</p>
            </>
        ),
    },
    {
        selector: '#checklist-multiselect',
        content: () => (
            <>
                <span className='checklist-tour-header'>Edit multiple items</span>
                <p className='typo_body--small checklist-tour-body'>
                    Select more than one checklist item at a time and mark as complete or delete if they aren't relevant.
                </p>
            </>
        ),
    },
];
