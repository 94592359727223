export default (response) => {
    if (response && response.acf) {
        return response.acf;
    } else {
        return {
            items_widget_title: 'Your Items',
            items_widget_tooltip: 'High value inventory can be edited up until your load date.',
            items_widget_zero_state_copy: 'It looks like you haven’t added any High Value Inventory items yet.',
            items_widget_zero_state_cta_label: 'Add High Value Inventory Items',
            page_content:
                "Adding or changing items in this list doesn't affect your estimate.<br />< br />Your driver will have a hard copy of this form for you to sign on load day.",
            page_title: 'High Value Inventory',
            video: {
                video_id: 'dGiQaabX3_o',
                video_title: 'Untitled FVP',
            },
            video_heading: 'Have more questions about Valuation and High Value Inventory?',
            video_sub_heading: 'Watch our helpful video below to learn more.',
            video_id: 'dGiQaabX3_o',
            video_title: 'Untitled FVP',
            sub_heading:
                "<p>High Value Inventory (HVI) refers to items included in your shipment valued at $100+ per pound such as TVs, designer clothes and shoes, antiques and artwork.</p><p>If you opt for Full Value Protection, you'll need to record these items on the HVI form to ensure proper replacement or repair value in the unlikely event of a loss while in transit*.</p><p style='font-size: 10px'>*Subject to some important conditions</p>",
        };
    }
};
