export default (response) => {
    if (response && response.acf) {
        return response.acf;
    } else {
        return {
            contact_brand_widget: {
                contact_brand_team: 'Customer Care Team',
                contact_brand_widget_title: 'Contact Brand',
            },
            contacts_widget: {
                after_move_contact_label: 'After booking your move',
                booked_contact_zero_state: 'Your estimator has not been assigned yet',
                backup_contact_label: "If you can't reach anyone above",
                before_move_contact_label: 'Before booking your move',
                before_booking_contact_zero_state: 'Your estimator has not been assigned yet',
                contacts_widget_title: 'Who to Call',
                in_transit_contact_label: 'While your shipment is in transit',
                in_transit_contact_zero_state: 'Your driver has not been assigned yet',
            },
            page_title: 'Contacts',
            sub_heading:
                "We've included key contacts from our local agent who will be servicing your upcoming move. You can also add any other move related contacts for easy access in one centralized location.",
        };
    }
};
