import { checklistItemsMiddleware } from 'middleware/api/checklist';
import * as actions from 'store/actions/api/checklistItems';

export const checklistItems = (state = {}, action) => {
    switch (action.type) {
        case actions.CLEAR_CHECKLIST_ITEMS:
            return {
                data: null,
                error: false,
            };
        case actions.STORE_CHECKLIST_ITEMS:
            return {
                data: checklistItemsMiddleware(action.data),
                error: false,
            };
        case actions.CHECKLIST_ITEMS_ERROR:
            return {
                data: checklistItemsMiddleware(action.data),
                error: true,
            };
        case actions.CHECKLIST_ITEM_REMOVED:
            return {
                ...state,
                data: {
                    ...state.data,
                    tasks: state.data.tasks.filter((item) => item.id !== action.data),
                },
            };
        case actions.CHECKLIST_ITEM_EDITED:
            return {
                ...state,
                data: {
                    ...state.data,
                    tasks: state.data.tasks.map((item) => {
                        if (item.id === action.data.id) {
                            return action.data;
                        }
                        return item;
                    }),
                },
            };
        case actions.CHECKLIST_ITEMS_COMPLETED:
            return {
                ...state,
                data: {
                    ...state.data,
                    tasks: state.data.tasks.map((item) => {
                        if (action.data.includes(item.id)) {
                            return {
                                ...item,
                                status: 'COMPLETED',
                                completed: true,
                            };
                        }
                        return item;
                    }),
                },
            };
        case actions.CHECKLIST_ITEMS_DELETED:
            return {
                ...state,
                data: {
                    ...state.data,
                    tasks: state.data.tasks.filter((item) => !action.data.includes(item.id)),
                },
            };
        default:
            return state;
    }
};
