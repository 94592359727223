interface DrawerAction {
    type: string;
}
export const OPEN_PAYMENT_DRAWER = 'OPEN_PAYMENT_DRAWER';
export const CLOSE_PAYMENT_DRAWER = 'CLOSE_PAYMENT_DRAWER';

export interface DrawerState {
    paymentDrawer: {
        open: boolean;
    };
}

export const drawerInitialState: DrawerState = {
    paymentDrawer: {
        open: false,
    },
};

export const drawer = (state = drawerInitialState, action: DrawerAction) => {
    switch (action.type) {
        case OPEN_PAYMENT_DRAWER:
            return {
                paymentDrawer: {
                    open: true,
                },
            };
        case CLOSE_PAYMENT_DRAWER:
            return {
                paymentDrawer: {
                    open: false,
                },
            };
        default:
            return state;
    }
};
